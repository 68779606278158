import React from 'react'
//component
import Seo from '../components/SEO'
const TemplateSolution = ({ location, pageContext }) => {
  return (
    <>
      <Seo title={pageContext.title} slug={location.pathname} />

      <div className='solution'>
        <div className='container'>
          <p>Page en construction</p>
        </div>
      </div>
    </>
  )
}

export default TemplateSolution
